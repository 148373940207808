import React from 'react'
import CommonMetatags from 'components/metatags/CommonMetatags'

function NotFoundPage() {
  return (
    <>
      <CommonMetatags indexed={false} />
      <p>Désolé, cette page n&apos;existe pas.</p>
    </>
  )
}

export default NotFoundPage
